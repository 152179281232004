import { configureStore } from "@reduxjs/toolkit";
import databaseTable from "./slices/databaseTable";
import onboarding from "./slices/onboarding";
import kanban from "./slices/kanban";
import vetrina from "./slices/vetrina";
import main from "./slices/main";

const store = configureStore({
  reducer: {
    databaseTable,
    onboarding,
    kanban,
    vetrina,
    main,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: ["onboarding.steps"],
        ignoredActions: ["onboarding/setErrorMessage"],
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
