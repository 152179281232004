import { useEffect } from "react";
import { IOnboardingStep } from "../../../types/onboarding";

// Hook personalizzato per gestire l'autofocus del primo campo in ogni step
// Quando si cambia step, il primo campo editabile viene automaticamente focalizzato
export const useStepAutoFocus = (
  stepIndex: number,
  fields?: NonNullable<IOnboardingStep["fields"]>[number][]
) => {
  useEffect(() => {
    // Trova il primo campo che può ricevere il focus
    // Considera solo i campi di tipo text, phone, textarea o select
    const firstInput = fields?.find((field) =>
      ["text", "phone", "textarea", "select"].includes(field.type)
    );

    if (firstInput) {
      // Usa setTimeout per assicurarsi che il DOM sia pronto
      setTimeout(() => {
        // Cerca l'elemento nel DOM usando l'attributo data-field-key
        const inputElement = document.querySelector(
          `[data-field-key="${firstInput.key}"] input, [data-field-key="${firstInput.key}"] textarea, [data-field-key="${firstInput.key}"] select`
        );

        // Se trova l'elemento, imposta il focus
        if (inputElement instanceof HTMLElement) {
          inputElement.focus();
        }
      }, 0);
    }
  }, [stepIndex, fields]); // Si attiva quando cambia lo step o i campi
};
