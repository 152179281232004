import React from "react";
import { FieldRenderer } from "../FieldRenderer";
import { IOnboardingStep } from "../../../types/onboarding";
import { useStepAutoFocus } from "../hooks/useStepAutoFocus";

interface SimpleFieldsProps {
  fields: NonNullable<IOnboardingStep["fields"]>[number][];
  formData: any;
  setFieldValue: (data: any) => void;
  multipleOptionStep: boolean;
  stepIndex: number;
}

export const SimpleFields: React.FC<SimpleFieldsProps> = ({
  fields,
  formData,
  setFieldValue,
  multipleOptionStep,
  stepIndex,
}) => {
  useStepAutoFocus(stepIndex, fields);

  return (
    <div
      className={`fields-container ${
        multipleOptionStep ? "multiple-options-step" : ""
      }`}
    >
      {fields?.map((field, i) => (
        <FieldRenderer
          key={i}
          multipleOptionStep={multipleOptionStep}
          field={field}
          formData={formData}
          setFieldValue={setFieldValue}
        />
      ))}
    </div>
  );
};
