import React from "react";
import { Plus } from "@phosphor-icons/react";
import Button from "../../Button";

interface AddExperienceButtonProps {
  disabled: boolean;
  onClick: () => void;
}

export const AddExperienceButton: React.FC<AddExperienceButtonProps> = ({
  disabled,
  onClick,
}) => (
  <Button
    className={[
      "button-instance add-btn",
      disabled ? "button-disabled" : "",
    ].join(" ")}
    property1="secondary"
    text={"Aggiungi Esperienza" as any}
    onClick={onClick}
    leftDecorator={(<Plus weight="bold" size={16} />) as any}
  >
    &nbsp;
  </Button>
);
