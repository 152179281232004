import { useOnboardingActions } from "../../../store/slices/onboarding";
import { getNestedValue } from "../../../utils/onboarding";
import { IOnboardingStep } from "../../../types/onboarding";

export const useOnboardingValidation = (
  formData: any,
  stepIndex: number,
  steps: IOnboardingStep[]
) => {
  const { setErrorMessage, setErrorFieldKey } = useOnboardingActions();

  const validateFields = () => {
    const validators =
      steps[stepIndex]?.fields?.filter((field) => field.validators) ?? [];

    for (const field of validators) {
      for (const validator of field.validators ?? []) {
        if (!validator(formData[field.key])) {
          setErrorMessage("Il campo è invalido");
          setErrorFieldKey(field.key);
          return false;
        }
      }
    }
    return true;
  };

  const validateMandatoryFields = (
    composableBlock = false,
    blockIndex?: number
  ) => {
    const hasMissingMandatoryField = steps[stepIndex].fields?.some((field) => {
      const dependencies = field.dependencies ?? [];
      let isDependencyValid = true;

      for (const dependency of dependencies) {
        const value = composableBlock
          ? getNestedValue(
              formData,
              dependency.key,
              blockIndex,
              steps[stepIndex].key
            )
          : getNestedValue(formData, dependency.key);

        if (!value?.includes(dependency.value)) {
          isDependencyValid = false;
          break;
        }
      }

      if (!isDependencyValid) return false;

      if (field.mandatory) {
        const value = composableBlock
          ? getNestedValue(
              formData,
              field.key,
              blockIndex,
              steps[stepIndex].key
            )
          : getNestedValue(formData, field.key);

        if (!value || (!value.length && typeof value !== "boolean")) {
          setErrorMessage(
            `Il campo ${field.label ? `${field.label} ` : ""}è obbligatorio`
          );
          setErrorFieldKey(field.key);
          return true;
        }
      }
      return false;
    });

    return !hasMissingMandatoryField;
  };

  return {
    validateFields,
    validateMandatoryFields,
  };
};
