import { IOnboardingStep } from "../../types/onboarding";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import {
  bindActionCreators,
  createSlice,
  current as cu,
  PayloadAction,
} from "@reduxjs/toolkit";
import { ONBOARDING_STEPS } from "../../constants/onboarding";
import {
  adaptNewExperiencesToOldFormat,
  adaptOldExperiencesToNewFormat,
  initializeEmptyFormData,
  initializeEmptyStep,
  saveToFirebase,
} from "../../utils/onboarding";

// Interfaccia che definisce la struttura dello stato dell'onboarding
interface OnboardingState {
  // Indice dello step corrente nel processo di onboarding
  stepIndex: number;

  // Array contenente tutti gli step disponibili con le loro configurazioni
  steps: IOnboardingStep[];

  // Messaggio di errore da mostrare all'utente
  errorMessage: string | null;

  // Chiave del campo che ha generato l'errore
  errorFieldKey: string | null;

  // Dati del form raccolti durante l'onboarding
  formData: Record<string, any>;
}

// Stato iniziale dell'onboarding
const initialState: OnboardingState = {
  stepIndex: 0,
  steps: ONBOARDING_STEPS,
  errorMessage: null,
  errorFieldKey: null,
  formData: initializeEmptyFormData(),
};

// Slice di Redux che gestisce la logica dell'onboarding
export const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    // Incrementa l'indice dello step corrente
    // Usato per avanzare al prossimo step
    increaseStepIndex: (state) => {
      state.stepIndex += 1;
    },

    // Decrementa l'indice dello step corrente
    // Usato per tornare allo step precedente
    reduceStepIndex: (state) => {
      state.stepIndex -= 1;
    },

    // Imposta l'indice dello step corrente
    setStepIndex: (state, action: PayloadAction<number>) => {
      state.stepIndex = action.payload;
    },

    // Imposta un nuovo valore per un campo del form
    // Gestisce sia campi semplici che campi all'interno di blocchi composabili
    setFieldValue: (
      state,
      action: PayloadAction<{
        key: string;
        value: any;
        composeIndex?: number;
        stepKey?: string;
      }>
    ) => {
      const { key, value, composeIndex, stepKey } = action.payload;

      // Se il campo fa parte di un blocco composabile
      if (typeof composeIndex === "number" && stepKey) {
        // Inizializza l'array se non esiste
        if (!state.formData[stepKey]) {
          state.formData[stepKey] = [];
        }
        // Inizializza l'oggetto del blocco se non esiste
        if (!state.formData[stepKey][composeIndex]) {
          state.formData[stepKey][composeIndex] = {};
        }

        // Gestisce chiavi nidificate per blocchi composabili
        const keys = key.split(".");
        let current = state.formData[stepKey][composeIndex];

        for (let i = 0; i < keys.length - 1; i++) {
          if (!(keys[i] in current)) {
            current[keys[i]] = {};
          }
          current = current[keys[i]];
        }
        current[keys[keys.length - 1]] = value;
      } else {
        // Gestisce chiavi nidificate per campi semplici
        const keys = key.split(".");
        let current = state.formData;

        for (let i = 0; i < keys.length - 1; i++) {
          if (!(keys[i] in current)) {
            current[keys[i]] = {};
          }
          current = current[keys[i]];
        }
        current[keys[keys.length - 1]] = value;
      }
    },

    // Imposta il messaggio di errore da mostrare all'utente
    setErrorMessage: (state, action: PayloadAction<string | null>) => {
      state.errorMessage = action.payload;
    },

    // Imposta la chiave del campo che ha generato l'errore
    setErrorFieldKey: (state, action: PayloadAction<string | null>) => {
      state.errorFieldKey = action.payload;
    },

    // Inizializza gli step disponibili nell'onboarding
    setSteps: (state, action: PayloadAction<IOnboardingStep[]>) => {
      state.steps = action.payload;
    },

    // Pre-compila i dati del form
    // Usato quando si caricano dati esistenti
    fillFormData: (state, action: PayloadAction<Record<string, any>>) => {
      let adaptedFormData = action.payload;
      if ("experiences" in adaptedFormData) {
        adaptedFormData["experiences"] = adaptOldExperiencesToNewFormat(
          adaptedFormData.experiences
        );
      }
      state.formData = adaptedFormData;
    },

    // Inizializza i dati del form per un nuovo step
    initializeEmptyStep: (state, action: PayloadAction<IOnboardingStep>) => {
      let stepData = initializeEmptyStep(action.payload);
      if ("experiences" in stepData) {
        stepData["experiences"] = adaptNewExperiencesToOldFormat(
          stepData.experiences
        );
      }
      if (state.formData.codice_fiscale) {
        saveToFirebase(state.formData.codice_fiscale, stepData);
      }
    },
  },
  extraReducers(builder) {},
});

// Esporta le azioni del slice
export const {
  increaseStepIndex,
  reduceStepIndex,
  setFieldValue,
  setErrorMessage,
  setErrorFieldKey,
  setSteps,
  fillFormData,
} = onboardingSlice.actions;

// Hook personalizzato per accedere allo stato dell'onboarding
export const useOnboardingState = () => {
  return useSelector(
    (state: { onboarding: OnboardingState }) => state.onboarding
  );
};

// Hook personalizzato per accedere alle azioni dell'onboarding
export const useOnboardingActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(onboardingSlice.actions, dispatch);
};

export default onboardingSlice.reducer;
