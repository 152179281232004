import React from "react";
import { FieldRenderer } from "../FieldRenderer";
import { IOnboardingStep } from "../../../types/onboarding";
import { useStepAutoFocus } from "../hooks/useStepAutoFocus";

interface ComposableFieldsProps {
  nonComposableFields: NonNullable<IOnboardingStep["fields"]>[number][];
  composableBlocks: any[];
  formData: any;
  setFieldValue: (data: any) => void;
  multipleOptionStep: boolean;
  stepIndex: number;
}

export const ComposableFields: React.FC<ComposableFieldsProps> = ({
  nonComposableFields,
  composableBlocks,
  formData,
  setFieldValue,
  multipleOptionStep,
  stepIndex,
}) => {
  useStepAutoFocus(stepIndex, nonComposableFields);
  return (
    <div
      className={`fields-container ${
        multipleOptionStep ? "multiple-options-step" : ""
      }`}
    >
      {nonComposableFields?.map((field, i) => (
        <FieldRenderer
          key={i}
          field={field}
          formData={formData}
          setFieldValue={setFieldValue}
          multipleOptionStep={multipleOptionStep}
        />
      ))}
      <div className="composable-blocks-container">
        {composableBlocks?.map((block, j) => (
          <div key={j} className="composable-block">
            {block?.map((field: any, k: number) => {
              if (!field) return null;
              return (
                <FieldRenderer
                  key={k}
                  field={field}
                  formData={formData}
                  setFieldValue={setFieldValue}
                  multipleOptionStep={multipleOptionStep}
                  composableBlock={true}
                  index={j}
                />
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
};
