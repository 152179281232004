import React from "react";
import { Warning } from "@phosphor-icons/react";

interface ErrorBannerProps {
  message: string;
}

export const ErrorBanner: React.FC<ErrorBannerProps> = ({ message }) => {
  if (!message) return null;

  return (
    <div className="banner error-message-container">
      <div className="div container-error-message">
        <p>
          <Warning size={24} weight="bold" color="#E31B23" />
        </p>
        <div className="text-frame">
          <div className="headline-text">Errore</div>
          <div className="paragraph-text">{message}</div>
        </div>
      </div>
    </div>
  );
};
