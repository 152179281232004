import React, { useEffect } from "react";
import { StyledEditField } from "./StyledEditField";
import { IField } from "../../../types/filterFields";
import { COUNTRIES } from "../../../constants/countries/countriesNationalities";

interface IEditField {
  onChange: (e: string | string[]) => void;
  className?: string;
  value: string | string[];
  objKey: string;
  placeholder?: string;
  obj?: IField;
  multiple?: boolean;
}

const EditField = ({
  obj,
  onChange,
  className,
  value,
  objKey,
  placeholder = "",
  multiple = false,
}: IEditField) => {
  const [curValue, setCurValue] = React.useState<string | string[]>(
    multiple ? (value ? value : []) : value ? value : ""
  );

  const inputProps = Object.assign(
    {},
    placeholder && { placeholder },
    value && { value: curValue },
    className && { className }
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurValue(e.target.value);
    onChange(e.target.value);
  };

  return (
    <StyledEditField>
      {(obj && obj.choices) || obj?.type === "choice" ? (
        <>
          {" "}
          <label>{placeholder}</label>
          <select
            multiple={true}
            value={
              typeof curValue === "string" || typeof curValue === "boolean"
                ? [...[curValue]]
                : curValue
            }
            onChange={(e) => {
              const selectedOptions = e.target.selectedOptions;
              const values = Array.from(selectedOptions ?? []).map(
                (option) => option.value
              );
              setCurValue(values);
              onChange(multiple ? values : values[0]);
            }}
            className={className}
            // {...inputProps}
          >
            <option>Seleziona</option>
            {(() => {
              if (obj?.choices) {
                return obj.choices?.map((choice) => (
                  <option value={choice}>{choice}</option>
                ));
              }
              if (obj.key === "nazionalita" || obj.key === "cittadinanza") {
                return COUNTRIES.map((choice) => (
                  <option value={choice}>{choice}</option>
                ));
              }
            })()}
          </select>
        </>
      ) : (
        <>
          <label>{placeholder}</label>
          <input
            type={obj?.type === "date" ? "month" : "text"}
            onChange={handleChange}
            className={className}
            {...inputProps}
          />
        </>
      )}
    </StyledEditField>
  );
};

export default EditField;
