import React from "react";

interface StepHeaderProps {
  title: string;
  description?: string;
  banner?: string;
}

export const StepHeader: React.FC<StepHeaderProps> = ({
  title,
  description,
  banner,
}) => {
  return (
    <div className="typeforms-buttons header-card-step">
      <div className="title-container">
        <h1 className="title header-card-step__title">{title}</h1>
      </div>
      {banner && <img className="card-step__banner" src={banner} alt="" />}
      {description && (
        <h2 className="subtitle header-card-step__subtitle">{description}</h2>
      )}
    </div>
  );
};
