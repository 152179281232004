// File: /src/components/OnboardingStep/FieldRenderer.tsx
// Created Date: Saturday January 11th 2025
// Author: Christian Nonis <alch.infoemail@gmail.com>
// -----
// Last Modified: Saturday January 11th 2025 11:03:48 am
// Modified By: the developer formerly known as Christian Nonis at <alch.infoemail@gmail.com>
// -----

import React, { useState } from "react";
import { useOnboardingActions } from "../../store/slices/onboarding";
import { useOnboardingState } from "../../store/slices/onboarding";
import TypeForm from "../TypeForm";
import { CaretDown } from "@phosphor-icons/react";
import { PREFIXES } from "../../constants/phonePrefixes/prefixes";
import { IconInterfacesUser } from "../IconInterfacesUser";
import phoneIcon from "../../img/static/phoneIcon.svg";
import SelectChoice from "../SelectChoice/SelectChoise";
import DateForm from "../DateForm";
import { IOnboardingStep } from "../../types/onboarding";
import TextField from "../TextField/Textfield";
import Dropdown from "../atoms/Dropdown/Dropdown";
import checkboxFlagged from "../../img/static/checkbox-flagged.svg";
import checkboxUnFlagged from "../../img/static/checkbox-unflagged.svg";
import { getNestedValue } from "../../utils/onboarding";

// Componente che si occupa di renderizzare i diversi tipi di campi del form
// Supporta vari tipi di input:
// - text: campo di testo semplice
// - phone: campo per numero di telefono con prefisso
// - options: selezione multipla o singola
// - date: selezione data
// - textarea: area di testo
// - select: menu a tendina
// - checkbox: casella di spunta
export const FieldRenderer: React.FC<{
  field: NonNullable<IOnboardingStep["fields"]>[number];
  formData: any;
  setFieldValue: any;
  multipleOptionStep: boolean;
  composableBlock?: boolean;
  index?: number;
}> = ({
  field,
  formData,
  setFieldValue,
  multipleOptionStep,
  composableBlock,
  index,
}) => {
  // Stato per gestire l'espansione/collasso delle opzioni multiple
  const [optionKeyExpanded, setOptionKeyExpanded] = useState<string | null>(
    null
  );

  // Gestione del prefisso telefonico per i campi di tipo phone
  const [phonePrefixTmpHandler, setPhonePrefixTmpHandler] = useState<string>(
    PREFIXES[0].prefix
  );

  // Ottiene lo stato degli errori e lo step corrente
  const { errorFieldKey, stepIndex, steps } = useOnboardingState();
  const { setErrorMessage } = useOnboardingActions();

  // Gestione delle dipendenze tra campi
  // Un campo può essere mostrato solo se un altro campo ha un certo valore
  const dependencies = field.dependencies ?? [];
  let isDependencyValid = true;

  // Verifica tutte le dipendenze del campo
  for (const dependency of dependencies) {
    if (
      !getNestedValue(
        formData,
        dependency.key,
        index,
        steps[stepIndex].key
      )?.includes(dependency.value)
    ) {
      isDependencyValid = false;
      break;
    }
  }

  // Funzione che gestisce il cambiamento di valore del campo
  // Gestisce in modo diverso i vari tipi di input
  const handleChange = (
    value: string | boolean,
    type: "text" | "phone" | "date" | "options" | "checkbox"
  ) => {
    // Gestisce la chiave del campo in modo diverso se è parte di un blocco composabile
    const key = composableBlock
      ? field.key.replace(`${steps[stepIndex].key}.`, "")
      : field.key;

    switch (type) {
      case "phone":
        // Per i campi telefono, combina il prefisso con il numero
        const updatedValue = `${phonePrefixTmpHandler.trim()}${(
          value as string
        ).trim()}`;
        let updatedData: any = {
          key,
          value: updatedValue,
        };
        if (composableBlock) {
          updatedData = {
            key,
            value: updatedValue,
            composeIndex: index,
            stepKey: steps[stepIndex].key,
          };
        }
        setFieldValue(updatedData);
        break;
      case "options":
        // Per i campi opzione, gestisce la selezione/deselezione
        if (
          getNestedValue(
            formData,
            field.key,
            index,
            steps[stepIndex].key
          )?.includes(value)
        ) {
          // Rimuove il valore se già selezionato
          let newValue = "";
          if (field.multiple) {
            getNestedValue(
              formData,
              field.key,
              index,
              steps[stepIndex].key
            ).filter((v: string) => v !== value);
          } else {
            newValue = "";
          }
          setErrorMessage(null);
          let updatedData: any = {
            key: field.key,
            value: newValue,
          };
          if (composableBlock) {
            updatedData = {
              key,
              value: newValue,
              composeIndex: index,
              stepKey: steps[stepIndex].key,
            };
          }
          setFieldValue(updatedData);
        } else {
          // Aggiunge il valore se non supera il massimo consentito
          if (
            field.max &&
            (
              getNestedValue(
                formData,
                field.key,
                index,
                steps[stepIndex].key
              ) ?? []
            ).length >= field.max
          ) {
            setErrorMessage(`Puoi selezionare al massimo ${field.max} opzioni`);
            return;
          }
          let updatedData: any = {
            key: field.key,
            value: field.multiple
              ? [
                  ...(getNestedValue(
                    formData,
                    field.key,
                    index,
                    steps[stepIndex].key
                  ) ?? []),
                  value,
                ]
              : value,
          };
          if (composableBlock) {
            updatedData = {
              key,
              value: field.multiple
                ? [
                    ...(getNestedValue(
                      formData,
                      field.key,
                      index,
                      steps[stepIndex].key
                    ) ?? []),
                    value,
                  ]
                : value,
              composeIndex: index,
              stepKey: steps[stepIndex].key,
            };
          }
          setFieldValue(updatedData);
        }
        break;
      default: {
        let updatedData: any = {
          key,
          value: value,
        };
        if (composableBlock) {
          updatedData = {
            key,
            value: value,
            composeIndex: index,
            stepKey: steps[stepIndex].key,
          };
        }
        setFieldValue(updatedData);
      }
    }
  };

  // Non renderizza nulla se le dipendenze non sono soddisfatte
  if (!isDependencyValid) return null;

  // Renderizza il campo appropriato in base al tipo
  return (
    <div data-field-key={field.key}>
      {field.type !== "options" &&
      field.type !== "select" &&
      field.type !== "checkbox" ? (
        <h3 className="label-field">{field.label}</h3>
      ) : (
        <></>
      )}
      {(() => {
        if (field.type === "text") {
          return (
            <TypeForm
              className={`type-form-instance ${
                errorFieldKey === field.key ? "error" : ""
              }`}
              label={field.label}
              value={formData[field.key]}
              placeholder={field.placeholder}
              onChange={(value: string) => handleChange(value, "text")}
            />
          );
        }
        if (field.type === "phone") {
          return (
            <TypeForm
              className={`type-form-instance ${
                errorFieldKey === field.key ? "error" : ""
              }`}
              label={field.label}
              type="number"
              placeholder={field.placeholder}
              value={formData[field.key]?.replace(phonePrefixTmpHandler, "")}
              prefix={
                (
                  <div className="wrapper-select-prefix">
                    <p>{phonePrefixTmpHandler}</p>
                    <select
                      className="select-number-prefix"
                      onChange={(e) => {
                        setPhonePrefixTmpHandler(e.target.value.trim());
                      }}
                    >
                      {PREFIXES.map((prefix) => (
                        <option key={prefix.prefix} value={`${prefix.prefix} `}>
                          {prefix.prefix} {prefix.country}
                        </option>
                      ))}
                    </select>
                  </div>
                ) as any // Oh yeah
              }
              override={
                <IconInterfacesUser
                  className="icon-interfaces-user-instance"
                  combinedShape={phoneIcon}
                />
              }
              onChange={(value: string) => {
                handleChange(value, "phone");
              }}
            />
          );
        }
        if (field.type === "options") {
          return (
            <div
              className={`options-container ${
                multipleOptionStep ? "multiple-options" : ""
              } ${errorFieldKey === field.key ? "error" : ""}`}
              onClick={(e) => {
                e.stopPropagation();
                if (optionKeyExpanded !== field.key) {
                  setOptionKeyExpanded(multipleOptionStep ? field.key : null);
                } else if (optionKeyExpanded === field.key) {
                  setOptionKeyExpanded(null);
                }
              }}
            >
              {multipleOptionStep ? (
                <div className="options-container__header">
                  <h3 className="options-container__title">{field.label}</h3>
                  <div className="options-container__choices-length">
                    <p>
                      {field.multiple
                        ? getNestedValue(
                            formData,
                            field.key,
                            index,
                            steps[stepIndex].key
                          )?.length ?? 0
                        : 1}
                    </p>
                  </div>
                  <CaretDown size={14} weight="bold" color="#898989" />
                </div>
              ) : (
                <></>
              )}
              <div
                className={`selection-element-horrizonatal ${
                  optionKeyExpanded === field.key && multipleOptionStep
                    ? "expanded"
                    : "collapsed"
                }`}
              >
                {field.options?.map((item, i) => (
                  <SelectChoice
                    isClickedProp={getNestedValue(
                      formData,
                      field.key,
                      index,
                      steps[stepIndex].key
                    )?.includes(item.value)}
                    key={i}
                    text={item.label}
                    onClick={() => {
                      handleChange(item.value, "options");
                    }}
                  />
                ))}
              </div>
            </div>
          );
        }
        if (field.type === "date") {
          return (
            <DateForm
              className={`date-form-instance ${
                errorFieldKey === field.key ? "error" : ""
              }`}
              value={
                getNestedValue(
                  formData,
                  field.key,
                  index,
                  steps[stepIndex].key
                ) || ""
              }
              type="Month"
              onChange={(value) => {
                handleChange(value.target.value, "date");
              }}
            />
          );
        }
        if (field.type === "textarea") {
          return (
            <TextField
              maxLength={field.max}
              className=""
              label={field.placeholder}
              value={formData[field.key]}
              onChange={(value) => handleChange(value, "text")}
            />
          );
        }
        if (field.type === "select") {
          return (
            <Dropdown
              className={`select-field ${
                errorFieldKey === field.key ? "error" : ""
              }`}
              value={formData[field.key]}
              defaultValue={formData[field.key] ?? "Seleziona un opzione"}
              options={(field.options ?? []).map((option) => option.value)}
              label={field.label}
              placeholder="Seleziona un opzione"
              onClick={(value: string | string[]) =>
                handleChange(value as string, "options")
              }
            />
          );
        }
        if (field.type === "checkbox") {
          return (
            <label className={`label`}>
              <div
                className="checkbox"
                onClick={() => {
                  handleChange(
                    !getNestedValue(
                      formData,
                      field.key,
                      index,
                      steps[stepIndex].key
                    ),
                    "checkbox"
                  );
                }}
              >
                <img
                  alt="Combined shape"
                  src={
                    getNestedValue(
                      formData,
                      field.key,
                      index,
                      steps[stepIndex].key
                    )
                      ? checkboxFlagged
                      : checkboxUnFlagged
                  }
                />
              </div>
              <div className="text">{field.label}</div>
            </label>
          );
        }
      })()}
      {field.placeholder &&
      field.type !== "phone" &&
      field.type !== "text" &&
      field.type !== "textarea" ? (
        <p className="placeholder-field">{field.placeholder}</p>
      ) : (
        <></>
      )}
    </div>
  );
};
