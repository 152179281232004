// File: /src/components/OnboardingStep/styles.ts
// Created Date: Tuesday December 10th 2024
// Author: Christian Nonis <alch.infoemail@gmail.com>
// -----
// Last Modified: Tuesday December 10th 2024 4:01:26 pm
// Modified By: the developer formerly known as Christian Nonis at <alch.infoemail@gmail.com>
// -----

import styled from "styled-components";

export const StyledOnboardingStep = styled.div<{ composable?: any }>`
  flex: 1;

  .onboarding-interno {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  .header-card-step {
    margin-bottom: 24px;
    gap: 0;

    h1,
    h2 {
      white-space: wrap;
      text-align: center;
    }

    &__title {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 0px;
      overflow: hidden;
    }

    &__subtitle {
      font-size: 16px;
      font-weight: 400;
      margin-top: 8px;
    }
  }

  .card-step {
    &__banner {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .composable-blocks-container {
    display: flex;
    flex-direction: column;
    gap: 64px;
  }

  .fields-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    margin-bottom: 36px;
    margin-top: 24px;

    .composable-block {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .type-form-instance {
      &.error {
        border: 1px solid #e31b23;
        background-color: #fff7f7;
      }
    }

    .date-form-instance {
      &.error {
        border: 1px solid #e31b23;
        background-color: #fff7f7;
      }
    }

    .selection-element-horrizonatal {
      display: flex;
      flex-direction: row;
      gap: 8px;

      .select-choice {
        cursor: pointer;
      }
    }
  }

  .error-message-container {
    margin-bottom: 24px;

    .container-error-message {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
    }
  }

  .select-field {
    &.error {
      .select {
        border: 1px solid #e31b23;
        background-color: #fff7f7;
      }
    }
  }

  .multiple-options-step {
    .options-container {
      border: 1px solid #cccababa;
      border-radius: 8px;
      padding: 12px;
      background-color: #fafafa;

      &.error {
        border: 1px solid #e31b23;
        background-color: #fff7f7;
      }

      &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      &__title {
        font-size: 16px;
        font-weight: 700;
        margin-top: 0;
        margin-bottom: 0;
        padding-left: 4px;
      }

      &__choices-length {
        font-size: 10px;
        font-weight: 800;
        color: #fff;
        background-color: #cccccc;
        margin-left: auto;
        margin-right: 8px;
        border-radius: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 16px;
        height: 16px;

        p {
          margin: 0;
        }
      }
    }

    .label-field {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 8px;
      margin-top: 0;
    }

    .placeholder-field {
      font-size: 12px;
      font-weight: 400;
      margin-top: 8px;
      color: #898989;
    }

    .multiple-options {
      cursor: pointer;

      .selection-element-horrizonatal {
        margin-top: 16px;
      }
    }

    .selection-element-horrizonatal {
      &.collapsed {
        display: none;
      }

      &.expanded {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }
  }

  .add-btn {
    margin-top: 16px;
  }

  .button-disabled {
    opacity: 0.2;
  }

  .checkbox {
    background-color: #ececec;
    width: 20px;
    height: 18px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    img {
      margin: 0;
      padding: 0;
      filter: brightness(0);
    }
  }
`;
