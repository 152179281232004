import React, { useState } from "react";
import Button from "../../components/Button/index";
import { IconInterfacesLock } from "../../components/IconInterfacesLock";
import { TypeForm } from "../../components/TypeForm/index";
import "./style.css";
import bazeLogo from "../../img/static/bazeLogo.png";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import Logo from "../../components/Logo/Logo";
import ErrorMessageInForm from "../../components/ErrorMessageInForm";
import { setIsLoading, setMultifactorError } from "../../store/slices/main";
import { useDispatch } from "react-redux";
import {
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import { app } from "../../lib/firebase/config";
import { saveSession } from "../../utils/auth/session";

export const SignInAdminPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const auth = getAuth();

  const [errorMessage, setErrorMessage] = useState("");

  const dr = useDispatch();

  const handleClickLoginButton = async () => {
    // Perform additional actions here
    dr(setIsLoading(true));
    const db = getFirestore(app);
    const docRef = collection(db, "internal_users");
    const q = query(docRef, where("email", "==", email));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.docs.length === 0) {
      setErrorMessage("Email o password errati");
      dr(setIsLoading(false));
      return;
    }

    await saveSession({ email });

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;

        // if (user.phoneNumber) {
        const searchParams = new URLSearchParams(window.location.search);
        const from = searchParams.get("from") || "/home";
        setIsLoading(false);

        navigate(from);

        // NOTE: removed 2fa, to re-enable uncomment the following line and anable in firbase console
        // navigate(
        //   `/r/impl-2fa?cf=${
        //     querySnapshot.docs[0].data().codice_fiscale
        //   }&code=true&from=${from}`,
        //   {
        //     replace: false,
        //   }
        // );
        // }
      })
      .catch((error) => {
        if (error.message.includes("(auth/multi-factor-auth-required)")) {
          dr(setMultifactorError(error));
          const searchParams = new URLSearchParams(window.location.search);
          const from = searchParams.get("from") || "/home";
          navigate(
            `/r/impl-2fa?cf=${
              querySnapshot.docs[0].data().codice_fiscale
            }&code=true&from=${from}`,
            {
              replace: false,
            }
          );
        }
        let errorMessage = "Si è verificato un errore, perfavore riprova.";
        if (error.message.includes("wrong-password")) {
          errorMessage = "Credeziali errate, perfavore riprova.";
        }
        setErrorMessage(errorMessage);
        console.log(error);
      })
      .finally(() => {
        dr(setIsLoading(false));
      });
  };

  const forgetPassword = (value) => {
    navigate("/password-dimenticata", { replace: true }); // Move to Home page
  };

  const handleTypeEmailChange = (value) => {
    // Utilizza il valore nel componente genitore
    setEmail(value);
  };

  const handleTypePasswordChange = (value) => {
    // Utilizza il valore nel componente genitore

    setPassword(value);
  };

  return (
    <div className="log-in-interno">
      <Logo />
      <div className="login-screen">
        <div className="typeforms-buttons">
          <div className="title-container">
            <h1 className="title">Log In</h1>
          </div>
          <div className="div">
            <div className="div">
              <TypeForm
                className="type-form-instance"
                label="Email"
                value={email}
                onChange={handleTypeEmailChange}
              />
              <TypeForm
                className="type-form-instance"
                label="Password"
                onChange={handleTypePasswordChange}
                value={password}
                type="password"
                override={
                  <IconInterfacesLock className="design-component-instance-node" />
                }
              />
            </div>
            <div className="other-typeform">
              {/*
              <div className="ricordami-check">
                <Checkbox className="design-component-instance-node" property1="checked" />
                <div className="text-wrapper">Ricordami</div>
              </div>*/}
              <div className="text-wrapper-2" onClick={forgetPassword}>
                Hai dimenticato la password?
              </div>
            </div>
          </div>
          <ErrorMessageInForm message={errorMessage} />

          <div className="buttons">
            <Button
              className="button-instance"
              property1="primary"
              text="Accedi"
              onClick={handleClickLoginButton}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignInAdminPage;
