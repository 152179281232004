// File: /src/components/OnboardingStep/OnboardingStep.tsx
// Created Date: Tuesday December 10th 2024
// Author: Christian Nonis <alch.infoemail@gmail.com>
// -----
// Last Modified: Tuesday December 10th 2024 4:01:19 pm
// Modified By: the developer formerly known as Christian Nonis at <alch.infoemail@gmail.com>
// -----

import React, { useCallback, useEffect } from "react";
import { StyledOnboardingStep } from "./styles";
import {
  useOnboardingActions,
  useOnboardingState,
} from "../../store/slices/onboarding";
import Logo from "../Logo/Logo";
import { useOnboardingValidation } from "./hooks/useOnboardingValidation";
import { useComposableBlocks } from "./hooks/useComposableBlocks";
import { useStepNavigation } from "./hooks/useStepNavigation";
import { ErrorBanner } from "./components/ErrorBanner";
import { StepHeader } from "./components/StepHeader";
import { ComposableFields } from "./components/ComposableFields";
import { SimpleFields } from "./components/SimpleFields";
import { AddExperienceButton } from "./components/AddExperienceButton";
import AvantiIndietroStepButtons from "../AvantiIndietroStepButtons";
import { useSearchParams } from "react-router-dom";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { app } from "../../lib/firebase/config";

// Componente principale che gestisce il processo di onboarding step by step
// Si occupa di:
// 1. Gestire la navigazione tra gli step
// 2. Validare i dati inseriti dall'utente
// 3. Salvare i dati su Firebase
// 4. Gestire gli errori e i messaggi all'utente
const OnboardingStep: React.FC = () => {
  // Ottiene lo stato globale dell'onboarding dal Redux store
  // stepIndex: indice dello step corrente
  // steps: array con tutti gli step disponibili
  // errorMessage: eventuali messaggi di errore da mostrare
  // formData: dati inseriti dall'utente in tutti gli step
  const { stepIndex, steps, errorMessage, formData } = useOnboardingState();

  // Azioni disponibili per modificare lo stato dell'onboarding
  // setFieldValue: aggiorna un singolo campo del form
  // fillFormData: popola tutti i dati del form (usato per il pre-filling)
  const { setFieldValue, fillFormData, setStepIndex } = useOnboardingActions();

  // Hook per la validazione dei campi
  // validateFields: controlla la validità di tutti i campi dello step corrente
  // validateMandatoryFields: verifica che tutti i campi obbligatori siano compilati
  const { validateFields, validateMandatoryFields } = useOnboardingValidation(
    formData,
    stepIndex,
    steps
  );

  // Ottiene i parametri dall'URL (usati per il pre-filling dei dati)
  const [searchParams] = useSearchParams();

  // Funzione che recupera i dati dell'utente da Firebase
  // Viene chiamata all'inizio se è presente il codice fiscale nell'URL
  const getOnboardingData = useCallback(async (): Promise<Record<
    string,
    any
  > | null> => {
    try {
      const db = getFirestore(app);
      const codiceFiscale = searchParams.get("codice_fiscale");
      if (!codiceFiscale) return null;

      // Cerca il documento dell'utente usando il codice fiscale come chiave
      const docRef = doc(db, "external_users", codiceFiscale);
      const docSnap = await getDoc(docRef);

      // Se trova il documento, restituisce i dati
      if (docSnap.exists()) {
        return docSnap.data();
      }
      return null;
    } catch (err) {
      console.log(err);
      return null;
    }
  }, [searchParams]);

  // Effect che si occupa di pre-compilare i dati se disponibili
  // Si occupa anche di impostare l'indice dello step corrente
  useEffect(() => {
    const handleSearchParams = () => {
      const codiceFiscale = searchParams.get("codice_fiscale");
      if (codiceFiscale && codiceFiscale !== "undefined") {
        getOnboardingData().then((data) => {
          if (data) {
            fillFormData(data);
          }
        });
      }
      const stepIndex = searchParams.get("step");
      if (stepIndex) {
        setStepIndex(parseInt(stepIndex));
      }
    };
    handleSearchParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Hook per gestire i blocchi composabili (es: esperienze lavorative multiple)
  // composableBlocks: array dei blocchi di campi ripetibili
  // setComposableBlocks: funzione per aggiungere/rimuovere blocchi
  // getComposableBlocks: funzione per ottenere la struttura di un nuovo blocco
  // getNonComposableFields: funzione per ottenere i campi non ripetibili
  const {
    composableBlocks,
    setComposableBlocks,
    getComposableBlocks,
    getNonComposableFields,
  } = useComposableBlocks(steps, stepIndex, formData);

  // Hook per la navigazione tra gli step
  // Gestisce la logica di avanzamento/retrocessione tra gli step
  // Include la validazione e il salvataggio dei dati
  const { handleClickNextButton, handleClickPreviousButton } =
    useStepNavigation(
      stepIndex,
      steps,
      validateFields,
      validateMandatoryFields,
      formData,
      setComposableBlocks,
      getComposableBlocks
    );

  // Flag che indica se lo step corrente contiene più opzioni selezionabili
  // Viene usato per modificare il layout e il comportamento dei campi
  const multipleOptionStep =
    (steps[stepIndex]?.fields ?? []).filter((f) => f?.type === "options")
      .length > 1;

  // Flag che indica se lo step corrente permette di aggiungere più blocchi
  // (es: più esperienze lavorative)
  const composable = steps[stepIndex]?.compositeOptions;

  // Array dei campi che non fanno parte di blocchi ripetibili
  const nonComposableFields = getNonComposableFields();
  console.log(composableBlocks);
  // Il render del componente è diviso in sezioni:
  // 1. Header con logo e titolo dello step
  // 2. Campi del form (semplici o composabili)
  // 3. Messaggi di errore
  // 4. Pulsanti di navigazione
  // 5. Pulsante per aggiungere blocchi (se lo step è composabile)
  return (
    <StyledOnboardingStep composable={composable}>
      <div className="onboarding-interno">
        <Logo />
        <div className="onboarding-screen">
          <StepHeader
            title={steps[stepIndex]?.title ?? ""}
            description={steps[stepIndex]?.description}
            banner={steps[stepIndex]?.banner}
          />

          {composable ? (
            <ComposableFields
              nonComposableFields={nonComposableFields ?? []}
              composableBlocks={composableBlocks}
              formData={formData}
              setFieldValue={setFieldValue}
              multipleOptionStep={multipleOptionStep}
              stepIndex={stepIndex}
            />
          ) : (
            <SimpleFields
              fields={steps[stepIndex]?.fields ?? []}
              formData={formData}
              setFieldValue={setFieldValue}
              multipleOptionStep={multipleOptionStep}
              stepIndex={stepIndex}
            />
          )}

          {errorMessage && <ErrorBanner message={errorMessage} />}

          <AvantiIndietroStepButtons
            onNext={handleClickNextButton}
            onPrevious={handleClickPreviousButton}
            nextDisabled={true}
          />

          {steps[stepIndex]?.compositeOptions && (
            <AddExperienceButton
              disabled={
                formData[steps[stepIndex].key]?.length >=
                (steps[stepIndex]?.compositeOptions?.max ?? 0)
              }
              onClick={() => {
                setComposableBlocks((p) =>
                  p.length >= (steps[stepIndex]?.compositeOptions?.max ?? 0)
                    ? p
                    : [...p, ...getComposableBlocks()]
                );
              }}
            />
          )}
        </div>
      </div>
    </StyledOnboardingStep>
  );
};

export default OnboardingStep;
