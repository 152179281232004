import { IOnboardingStep } from "../types/onboarding";
import image from "../assets/onboarding-image.jpg";
import {
  codiceFiscaleValidator,
  telephoneNumberValidator,
  emailValidator,
  maggiorenneValidator,
} from "../utils/validators";
import { provincieItaliaList } from "./provincie";
import { COUNTRIES } from "./countries/countriesNationalities";

export enum WorkType {
  BADANTE = "Badante",
  COLF = "Colf",
  BABY_SITTER = "Babysitter",
}

export const HOME_WORK_TYPES = [
  "A ore mattina",
  "A ore pomeriggio",
  "A ore tutto il giorno",
  "Convivente tempo pieno (54 ore)",
  "Convivente tempo parziale",
  "Notturno",
  "Fine settimana o sostituzione riposi",
];

export const ONBOARDING_STEPS: IOnboardingStep[] = [
  {
    key: "welcome",
    title:
      "Stai cercando lavoro? Preparati a creare la tua candidatura lavorativa, segui la procedura ci vorranno solo pochi minuti.",
    description:
      "Tieni a portata di mano il tuo codice fiscale, ti servirà per la registrazione.",
    banner: image,
  },
  {
    key: "fourteenth-step",
    title: "Come ti chiami?* (Obbligatorio)",
    mandatory: true,
    fields: [
      {
        key: "nome",
        type: "text",
        placeholder: "Nome",
        mandatory: true,
      },
      {
        key: "cognome",
        type: "text",
        placeholder: "Cognome",
        mandatory: true,
      },
    ],
  },
  {
    key: "sixteenth-step",
    title: "Inserisci le tue informazioni di contatto?* (Obbligatorio)",
    mandatory: true,
    fields: [
      {
        key: "numero_telefono",
        type: "phone",
        placeholder: "Numero di telefono",
        mandatory: true,
        validators: [telephoneNumberValidator],
      },
    ],
  },
  {
    key: "provincia",
    title: "La tua provincia di residenza?* (Obbligatorio)",
    mandatory: true,
    fields: [
      {
        label: "Provincia",
        key: "provincia",
        type: "select",
        mandatory: true,
        options: provincieItaliaList.map((provincia) => ({
          label: provincia,
          value: provincia,
        })),
      },
    ],
  },
  {
    key: "fifteenth-step",
    title: "Il tuo codice fiscale?* (Obbligatorio)",
    mandatory: true,
    fields: [
      {
        key: "codice_fiscale",
        type: "text",
        validators: [codiceFiscaleValidator],
        placeholder: "Codice fiscale",
        mandatory: true,
      },
    ],
  },
  {
    key: "first-step",
    title: "Che lavoro stai cercando?* (Obbligatorio)",
    description: "Puoi selezionare più caselle.",
    fields: [
      {
        key: "tipo_lavoro",
        type: "options",
        label: "Tipo di lavoro",
        mandatory: true,
        multiple: true,
        options: [
          { label: "Badante", value: "Badante" },
          { label: "Colf", value: "Colf" },
          { label: "Babysitter", value: "Babysitter" },
          { label: "Governante", value: "Governante" },
          { label: "Autista", value: "Autista" },
          { label: "Direttore di casa", value: "Direttore di casa" },
          { label: "Maggiordomo", value: "Maggiordomo" },
          { label: "Cuoco", value: "Cuoco" },
          { label: "Giardiniere", value: "Giardiniere" },
        ],
      },
    ],
    mandatory: true,
  },
  {
    key: "second-step",
    title: "In quali modalità puoi lavorare?* (Obbligatorio)",
    description: "Puoi selezionare più caselle.",
    fields: [
      {
        key: "tipo_rapporto",
        type: "options",
        label: "Modalità di lavoro",
        multiple: true,
        mandatory: true,
        options: [
          { label: "A ore mattina", value: "A ore mattina" },
          { label: "A ore pomeriggio", value: "A ore pomeriggio" },
          { label: "A ore tutto il giorno", value: "A ore tutto il giorno" },
          {
            label: "Convivente tempo pieno (54 ore)",
            value: "Convivente tempo pieno (54 ore)",
          },
          {
            label: "Convivente tempo parziale",
            value: "Convivente tempo parziale",
          },
          { label: "Notturno", value: "Notturno" },
          {
            label: "Fine settimana o sostituzione riposi",
            value: "Fine settimana o sostituzione riposi",
          },
        ],
      },
    ],
    mandatory: true,
  },
  {
    key: "condizioni_accettabili",
    title: "Nel tuo lavoro, sei disponibile a:* (Obbligatorio)",
    description: "Puoi selezionare più caselle",
    fields: [
      {
        key: "condizioni_accettabili.badante",
        type: "options",
        mandatory: true,
        multiple: true,
        label: "Come badante",
        dependencies: [{ key: "tipo_lavoro", value: "Badante" }],
        options: [
          { label: "Lavorare il Weekend", value: "Lavorare il Weekend" },
          {
            label: "Assistere una sola persona anziana",
            value: "Assistere una sola persona anziana",
          },
          {
            label: "Assistere 2 persone anziane",
            value: "Assistere 2 persone anziane",
          },
          { label: "Assistere un uomo", value: "Assistere un uomo" },
          { label: "Assistere una donna", value: "Assistere una donna" },
        ],
      },
      {
        key: "condizioni_accettabili.colf",
        type: "options",
        mandatory: true,
        multiple: true,
        label: "Come colf",
        dependencies: [{ key: "tipo_lavoro", value: "Colf" }],
        options: [
          { label: "Lavorare il Weekend", value: "Lavorare il Weekend" },
          {
            label: "Accudire animali domestici",
            value: "Accudire animali domestici",
          },
          { label: "Nessuna di queste", value: "Nessuna di queste" },
        ],
      },
      {
        key: "condizioni_accettabili.baby_sitter",
        type: "options",
        mandatory: true,
        multiple: true,
        label: "Come babysitter",
        dependencies: [{ key: "tipo_lavoro", value: "Babysitter" }],
        options: [
          {
            label: "Assistere un bambino piu piccolo di un anno",
            value: "Assistere un bambino piu piccolo di un anno",
          },
          { label: "Lavorare il weekend", value: "Lavorare il weekend" },
          {
            label: "Accudire animali domestici",
            value: "Accudire animali domestici",
          },
          { label: "Nessuna di queste", value: "Nessuna di queste" },
        ],
      },
    ],
    mandatory: true,
  },
  {
    key: "hard_skills",
    title: "Cosa sai fare meglio?* (Obbligatorio)",
    description: "Puoi selezionare massimo 5 caselle",
    fields: [
      {
        key: "hard_skills.badante",
        type: "options",
        multiple: true,
        mandatory: true,
        max: 5,
        label: "Come badante",
        dependencies: [{ key: "tipo_lavoro", value: "Badante" }],
        options: [
          {
            label: "Preparare le medicine secondo le indicazioni del medico",
            value: "Preparare le medicine secondo le indicazioni del medico",
          },
          {
            label: "Aiutare la persona nell'igiene intima",
            value: "Aiutare la persona nell'igiene intima",
          },
          {
            label: "Aiutare la persona a mangiare",
            value: "Aiutare la persona a mangiare",
          },
          {
            label: "Aiutare la persona a camminare e alzarsi",
            value: "Aiutare la persona a camminare e alzarsi",
          },
          {
            label: "Assistere un malato di Alzheimer/demenza",
            value: "Assistere un malato di Alzheimer/demenza",
          },
          {
            label: "Assistere una persona con Ictus",
            value: "Assistere una persona con Ictus",
          },
          {
            label: "Assistere persona con Parkinson",
            value: "Assistere persona con Parkinson",
          },
          {
            label: "Assistere persona con disabilità",
            value: "Assistere persona con disabilità",
          },
          {
            label: "Assistere una persona con tumore",
            value: "Assistere una persona con tumore",
          },
          {
            label: "Misurare la pressione/ la glicemia",
            value: "Misurare la pressione/ la glicemia",
          },
        ],
      },
      {
        key: "hard_skills.colf",
        type: "options",
        multiple: true,
        mandatory: true,
        max: 5,
        dependencies: [{ key: "tipo_lavoro", value: "Colf" }],
        label: "Come colf",
        options: [
          {
            label:
              "Organizzare il tempo e i lavori come richiesto dalla famiglia",
            value:
              "Organizzare il tempo e i lavori come richiesto dalla famiglia",
          },
          {
            label: "Fare le pulizie quotidiane della casa",
            value: "Fare le pulizie quotidiane della casa",
          },
          {
            label: "Fare le grandi pulizie periodiche/mensili",
            value: "Fare le grandi pulizie periodiche/mensili",
          },
          {
            label: "Pulire il giardino o il terrazzo",
            value: "Pulire il giardino o il terrazzo",
          },
          {
            label: "Fare la raccolta differenziata della spazzatura",
            value: "Fare la raccolta differenziata della spazzatura",
          },
          {
            label: "Fare la lavatrice nel rispetto dei tessuti",
            value: "Fare la lavatrice nel rispetto dei tessuti",
          },
          {
            label: "Stirare e mettere i vestiti negli armadi",
            value: "Stirare e mettere i vestiti negli armadi",
          },
          {
            label: "Mettere a posto, lasciare la casa ordinata",
            value: "Mettere a posto, lasciare la casa ordinata",
          },
          {
            label: "Cucinare, preparare il pasto secondo le indicazioni",
            value: "Cucinare, preparare il pasto secondo le indicazioni",
          },
          {
            label: "Fare la spesa e piccole commissioni",
            value: "Fare la spesa e piccole commissioni",
          },
          {
            label:
              "Tenere puliti la dispensa e gli elettrodomestici: es. frigo, lavatrice, aspirapolvere...",
            value:
              "Tenere puliti la dispensa e gli elettrodomestici: es. frigo, lavatrice, aspirapolvere...",
          },
          {
            label: "Accudire gli animali domestici",
            value: "Accudire gli animali domestici",
          },
          {
            label: "Guidare",
            value: "Guidare",
          },
        ],
      },
      {
        key: "hard_skills.baby_sitter",
        type: "options",
        mandatory: true,
        multiple: true,
        max: 5,
        label: "Come babysitter",
        dependencies: [{ key: "tipo_lavoro", value: "Babysitter" }],
        options: [
          {
            label: "Fare sorveglianza al bambino in sicurezza",
            value: "Fare sorveglianza al bambino in sicurezza",
          },
          {
            label: "Accompagnare il bambino nelle attività dopo la scuola",
            value: "Accompagnare il bambino nelle attività dopo la scuola",
          },
          {
            label: "Organizzare momenti di gioco",
            value: "Organizzare momenti di gioco",
          },
          {
            label: "Aiutare il bambino a mangiare, fare il bagno, vestirsi",
            value: "Aiutare il bambino a mangiare, fare il bagno, vestirsi",
          },
          {
            label: "Tenere in ordine la stanza e i giochi puliti",
            value: "Tenere in ordine la stanza e i giochi puliti",
          },
          {
            label:
              "Cambiare il pannolino, dare la pappa/biberon, fare il bagnetto, fare la nanna",
            value:
              "Cambiare il pannolino, dare la pappa/biberon, fare il bagnetto, fare la nanna",
          },
          {
            label: "Accudire bambini molto piccoli 0-24 mesi",
            value: "Accudire bambini molto piccoli 0-24 mesi",
          },
          {
            label: "Accudire bambini di 2-5 anni di età",
            value: "Accudire bambini di 2-5 anni di età",
          },
          {
            label: "Accudire bambini 6-12 anni di età",
            value: "Accudire bambini 6-12 anni di età",
          },
          {
            label: "Lavorare in famiglie numerose (con 2 figli o più)",
            value: "Lavorare in famiglie numerose (con 2 figli o più)",
          },
        ],
      },
    ],
    mandatory: true,
  },
  {
    key: "conoscenza_italiano",
    title: "Qual è il tuo livello di Italiano?* (Obbligatorio)",
    description: "Puoi scegliere una sola casella",
    fields: [
      {
        key: "conoscenza_italiano",
        type: "options",
        mandatory: true,
        options: [
          { label: "Non lo parlo", value: "Non lo parlo" },
          { label: "Capisco e Parlo Poco", value: "Capisco e Parlo Poco" },
          { label: "Capisco e Parlo Bene", value: "Capisco e Parlo Bene" },
          { label: "Sono Madrelingua", value: "Sono Madrelingua" },
        ],
      },
    ],
    mandatory: true,
  },
  {
    key: "lingue_parlate",
    title: "Quali tra queste lingue sai parlare?* (Obbligatorio)",
    description: "Puoi scegliere più caselle",
    fields: [
      {
        key: "lingue_parlate",
        type: "options",
        multiple: true,
        mandatory: true,
        options: [
          { label: "Inglese", value: "Inglese" },
          { label: "Francese", value: "Francese" },
          { label: "Tedesco", value: "Tedesco" },
          { label: "Spagnolo", value: "Spagnolo" },
          { label: "Portoghese", value: "Portoghese" },
          { label: "Rumeno", value: "Rumeno" },
          { label: "Arabo", value: "Arabo" },
          { label: "Cinese", value: "Cinese" },
          { label: "Hindi", value: "Hindi" },
          { label: "Bengalese", value: "Bengalese" },
          { label: "Altro", value: "Altro" },
        ],
      },
    ],
    mandatory: true,
  },
  {
    key: "nameCertification",
    title: "Hai una qualifica professionale o certificazione delle competenze",
    description: "Esempio: OSS, UNI, corso >500h, etc.",
    fields: [
      {
        key: "qualifica_professionale",
        type: "options",
        multiple: true,
        options: [
          { label: "OSS", value: "OSS" },
          { label: "ASA", value: "ASA" },
          {
            label: "CERTIFICAZIONE UNI 11766 Patente di qualità",
            value: "CERTIFICAZIONE UNI 11766 Patente di qualità",
          },
          { label: "No, non ce l'ho", value: "No, non ce l'ho" },
          { label: "Altro", value: "Altro" },
        ],
      },
    ],
    mandatory: true,
  },
  {
    key: "experiences",
    title:
      "Descrivi la tua esperienza lavorativa più importante* (Obbligatorio)",
    description:
      "Inserisci una o più esperienza/e lavorativa/e significativa/e che hai fatto",
    compositeOptions: {
      max: 2,
      blocks: [
        {
          fields: [
            "experiences.experienceAs",
            "experiences.startExperience",
            "experiences.endExperience",
            "experiences.mansioni",
            "experiences.workModalityExperience",
          ],
        },
      ],
    },
    fields: [
      {
        key: "experiences.experienceAs",
        label: "Esperienza come",
        type: "options",
        options: [
          { label: "Badante", value: "Badante" },
          { label: "Colf", value: "Colf" },
          { label: "Babysitter", value: "Babysitter" },
        ],
        mandatory: true,
      },
      {
        key: "experiences.startExperience",
        label: "Mese inizio",
        type: "date",
        mandatory: true,
      },
      {
        key: "experiences.endExperience",
        label: "Mese fine",
        type: "date",
        placeholder: "Lascia vuoto se attuale",
        mandatory: false,
      },
      {
        key: "experiences.mansioni",
        label: "Mansioni",
        type: "options",
        multiple: true,
        dependencies: [{ key: "experiences.experienceAs", value: "Colf" }],
        options: [
          {
            label:
              "Organizzare il tempo e i lavori come richiesto dalla famiglia",
            value:
              "Organizzare il tempo e i lavori come richiesto dalla famiglia",
          },
          {
            label: "Fare le pulizie quotidiane della casa",
            value: "Fare le pulizie quotidiane della casa",
          },
          {
            label: "Fare le grandi pulizie periodiche/mensili",
            value: "Fare le grandi pulizie periodiche/mensili",
          },
          {
            label: "Pulire il giardino o il terrazzo",
            value: "Pulire il giardino o il terrazzo",
          },
          {
            label: "Fare la raccolta differenziata della spazzatura",
            value: "Fare la raccolta differenziata della spazzatura",
          },
          {
            label: "Fare la lavatrice nel rispetto dei tessuti",
            value: "Fare la lavatrice nel rispetto dei tessuti",
          },
          {
            label: "Stirare e mettere i vestiti negli armadi",
            value: "Stirare e mettere i vestiti negli armadi",
          },
          {
            label: "Mettere a posto, lasciare la casa ordinata",
            value: "Mettere a posto, lasciare la casa ordinata",
          },
          {
            label: "Cucinare, preparare il pasto secondo le indicazioni",
            value: "Cucinare, preparare il pasto secondo le indicazioni",
          },
          {
            label: "Fare la spesa e piccole commissioni",
            value: "Fare la spesa e piccole commissioni",
          },
          {
            label:
              "Tenere puliti la dispensa e gli elettrodomestici: es. frigo, lavatrice, aspirapolvere...",
            value:
              "Tenere puliti la dispensa e gli elettrodomestici: es. frigo, lavatrice, aspirapolvere...",
          },
          {
            label: "Accudire gli animali domestici",
            value: "Accudire gli animali domestici",
          },
          { label: "Guidare", value: "Guidare" },
        ],
      },
      {
        key: "experiences.mansioni",
        label: "Mansioni",
        type: "options",
        multiple: true,
        dependencies: [
          { key: "experiences.experienceAs", value: "Babysitter" },
        ],
        options: [
          {
            label: "Fare sorveglianza al bambino in sicurezza",
            value: "Fare sorveglianza al bambino in sicurezza",
          },
          {
            label: "Accompagnare il bambino nelle attività dopo la scuola",
            value: "Accompagnare il bambino nelle attività dopo la scuola",
          },
          {
            label: "Organizzare momenti di gioco",
            value: "Organizzare momenti di gioco",
          },
          {
            label: "Aiutare il bambino a mangiare, fare il bagno, vestirsi",
            value: "Aiutare il bambino a mangiare, fare il bagno, vestirsi",
          },
          {
            label: "Tenere in ordine la stanza e i giochi puliti",
            value: "Tenere in ordine la stanza e i giochi puliti",
          },
          {
            label:
              "Cambiare il pannolino, dare la pappa/biberon, fare il bagnetto, fare la nanna",
            value:
              "Cambiare il pannolino, dare la pappa/biberon, fare il bagnetto, fare la nanna",
          },
          {
            label: "Accudire bambini molto piccoli 0-24 mesi",
            value: "Accudire bambini molto piccoli 0-24 mesi",
          },
          {
            label: "Accudire bambini di 2-5 anni di età",
            value: "Accudire bambini di 2-5 anni di età",
          },
          {
            label: "Accudire bambini 6-12 anni di età",
            value: "Accudire bambini 6-12 anni di età",
          },
          {
            label: "Lavorare in famiglie numerose (con 2 figli o più)",
            value: "Lavorare in famiglie numerose (con 2 figli o più)",
          },
        ],
      },
      {
        key: "experiences.mansioni",
        label: "Mansioni",
        type: "options",
        multiple: true,
        dependencies: [{ key: "experiences.experienceAs", value: "Badante" }],
        options: [
          {
            label: "Preparare le medicine secondo le indicazioni del medico",
            value: "Preparare le medicine secondo le indicazioni del medico",
          },
          {
            label: "Aiutare la persona nell'igiene intima",
            value: "Aiutare la persona nell'igiene intima",
          },
          {
            label: "Aiutare la persona a mangiare",
            value: "Aiutare la persona a mangiare",
          },
          {
            label: "Aiutare la persona a camminare e alzarsi",
            value: "Aiutare la persona a camminare e alzarsi",
          },
          {
            label: "Assistere un malato di Alzheimer/demenza",
            value: "Assistere un malato di Alzheimer/demenza",
          },
          {
            label: "Assistere una persona con Ictus",
            value: "Assistere una persona con Ictus",
          },
          {
            value: "Assistere persona con Parkinson",
            label: "Assistere persona con Parkinson",
          },
          {
            label: "Assistere persona con disabilità",
            value: "Assistere persona con disabilità",
          },
          {
            label: "Assistere una persona con tumore",
            value: "Assistere una persona con tumore",
          },
          {
            label: "Misurare la pressione/ la glicemia",
            value: "Misurare la pressione/ la glicemia",
          },
        ],
      },
      {
        key: "experiences.workModalityExperience",
        label: "Tipo di lavoro",
        type: "options",
        options: [
          { label: "A ore mattina", value: "A ore mattina" },
          { label: "A ore pomeriggio", value: "A ore pomeriggio" },
          { label: "A ore tutto il giorno", value: "A ore tutto il giorno" },
          {
            label: "Convivente tempo pieno (54 ore)",
            value: "Convivente tempo pieno (54 ore)",
          },
          {
            label: "Convivente tempo parziale",
            value: "Convivente tempo parziale",
          },
          { label: "Notturno", value: "Notturno" },
          {
            label: "Fine settimana o sostituzione riposi",
            value: "Fine settimana o sostituzione riposi",
          },
        ],
        mandatory: true,
      },
    ],
    mandatory: true,
  },
  {
    title: "Che tipo di persona sei?",
    key: "sixth-step",
    mandatory: false,
    fields: [
      {
        key: "descrizione_personale_testo_libero",
        type: "textarea",
        placeholder: "Descriviti brevemente (facoltativo)",
        max: 500,
        mandatory: false,
        label: "Descriviti brevemente (facoltativo)",
      },
    ],
  },
  {
    key: "seventh-step",
    title: "Hai la patente?* (Obbligatorio)",
    mandatory: true,
    fields: [
      {
        key: "patente",
        type: "options",
        mandatory: true,
        options: [
          { label: "Ho la patente", value: "Ho la patente" },
          { label: "Non ho la patente", value: "Non ho la patente" },
        ],
      },
    ],
  },
  {
    key: "eighth-step",
    title: "Possiedi un auto?* (Obbligatorio)",
    mandatory: true,
    fields: [
      {
        key: "automunito",
        type: "options",
        mandatory: true,
        options: [
          { label: "Sono automunito", value: "Sono automunito" },
          { label: "NON sono automunito", value: "NON sono automunito" },
        ],
      },
    ],
  },
  {
    key: "ninth-step",
    title: "Sei?* (Obbligatorio)",
    mandatory: true,
    fields: [
      {
        key: "genere",
        type: "options",
        mandatory: true,
        options: [
          { label: "Uomo", value: "Uomo" },
          { label: "Donna", value: "Donna" },
        ],
      },
    ],
  },
  {
    title: "Dove abiti?* (Obbligatorio)",
    key: "eleventh-step",
    mandatory: true,
    fields: [
      {
        placeholder: "Indirizzo",
        key: "indirizzo",
        type: "text",
        mandatory: true,
      },
      {
        placeholder: "CAP",
        key: "codice_postale",
        type: "text",
        mandatory: false,
      },
      {
        placeholder: "Città",
        key: "citta",
        type: "text",
        mandatory: true,
      },
    ],
  },
  {
    key: "twelfth-step",
    title: "Dove sei nato/a?* (Obbligatorio)",
    mandatory: true,
    fields: [
      {
        key: "paese_origine",
        type: "select",
        mandatory: true,
        options: COUNTRIES.map((country) => ({
          label: country,
          value: country,
        })),
      },
    ],
  },
  {
    key: "thirteenth-step",
    title: "Quale cittadinanza hai?* (Obbligatorio)",
    mandatory: true,
    fields: [
      {
        key: "cittadinanza",
        type: "select",
        mandatory: true,
        options: COUNTRIES.map((country) => ({
          label: country,
          value: country,
        })),
      },
    ],
  },
  {
    key: "sixteenth-step",
    title: "Inserisci le tue informazioni di contatto?* (Obbligatorio)",
    mandatory: true,
    fields: [
      {
        key: "email",
        placeholder: "Email",
        type: "text",
        validators: [emailValidator],
        mandatory: true,
      },
    ],
  },
  {
    key: "tenth-step",
    title: "Qual è la tua data di nascita?* (Obbligatorio)",
    description: "Devi essere maggiorenne",
    mandatory: true,
    fields: [
      {
        key: "data_di_nascita",
        type: "date",
        mandatory: true,
        validators: [maggiorenneValidator],
      },
    ],
  },
  {
    key: "consentPrivacy",
    title: "Ultimo step",
    mandatory: true,
    fields: [
      {
        key: "consentPrivacy",
        label:
          "Ho letto l'informativa privacy e presto i consensi in essa indicati",
        type: "checkbox",
        mandatory: true,
      },
    ],
  },
];
