export const COUNTRIES = [
  "Afghanistan",
  "Isole Åland",
  "Albania",
  "Algeria",
  "Samoa Americane",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antartide",
  "Antigua e Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaigian",
  "Bahamas",
  "Bahrein",
  "Bangladesh",
  "Barbados",
  "Bielorussia",
  "Belgio",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bonaire, Sint Eustatius e Saba",
  "Bosnia ed Erzegovina",
  "Botswana",
  "Isola Bouvet",
  "Brasile",
  "Territorio britannico dell'Oceano Indiano",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Capo Verde",
  "Cambogia",
  "Camerun",
  "Canada",
  "Isole Cayman",
  "Repubblica Centrafricana",
  "Ciad",
  "Cile",
  "Cina",
  "Isola di Natale",
  "Isole Cocos",
  "Colombia",
  "Comore",
  "Congo (Repubblica del)",
  "Congo (Repubblica Democratica del)",
  "Isole Cook",
  "Costa Rica",
  "Costa d'Avorio",
  "Croazia",
  "Cuba",
  "Curaçao",
  "Cipro",
  "Repubblica Ceca",
  "Danimarca",
  "Gibuti",
  "Dominica",
  "Repubblica Dominicana",
  "Ecuador",
  "Egitto",
  "El Salvador",
  "Guinea Equatoriale",
  "Eritrea",
  "Estonia",
  "Etiopia",
  "Isole Falkland",
  "Isole Faroe",
  "Figi",
  "Finlandia",
  "Francia",
  "Guyana Francese",
  "Polinesia Francese",
  "Territori Francesi del Sud",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germania",
  "Ghana",
  "Gibilterra",
  "Grecia",
  "Groenlandia",
  "Grenada",
  "Guadalupa",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Isole Heard e McDonald",
  "Città del Vaticano",
  "Honduras",
  "Hong Kong",
  "Ungheria",
  "Islanda",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Irlanda",
  "Isola di Man",
  "Israele",
  "Italia",
  "Giamaica",
  "Giappone",
  "Jersey",
  "Giordania",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Corea del Nord",
  "Corea del Sud",
  "Kuwait",
  "Kirghizistan",
  "Laos",
  "Lettonia",
  "Libano",
  "Lesotho",
  "Liberia",
  "Libia",
  "Liechtenstein",
  "Lituania",
  "Lussemburgo",
  "Macao",
  "Macedonia del Nord",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldive",
  "Mali",
  "Malta",
  "Isole Marshall",
  "Martinica",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Messico",
  "Micronesia",
  "Moldavia",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Marocco",
  "Mozambico",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Paesi Bassi",
  "Nuova Caledonia",
  "Nuova Zelanda",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Isola Norfolk",
  "Isole Marianne Settentrionali",
  "Norvegia",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestina",
  "Panama",
  "Papua Nuova Guinea",
  "Paraguay",
  "Perù",
  "Filippine",
  "Pitcairn",
  "Polonia",
  "Portogallo",
  "Porto Rico",
  "Qatar",
  "Riunione",
  "Romania",
  "Russia",
  "Ruanda",
  "Saint-Barthélemy",
  "Sant'Elena, Ascensione e Tristan da Cunha",
  "Saint Kitts e Nevis",
  "Santa Lucia",
  "Saint Martin",
  "Saint Pierre e Miquelon",
  "Saint Vincent e Grenadine",
  "Samoa",
  "San Marino",
  "São Tomé e Príncipe",
  "Arabia Saudita",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten",
  "Slovacchia",
  "Slovenia",
  "Isole Salomone",
  "Somalia",
  "Sudafrica",
  "Georgia del Sud e Sandwich Australi",
  "Sud Sudan",
  "Spagna",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard e Jan Mayen",
  "Swaziland",
  "Svezia",
  "Svizzera",
  "Siria",
  "Taiwan",
  "Tagikistan",
  "Tanzania",
  "Thailandia",
  "Timor Est",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad e Tobago",
  "Tunisia",
  "Turchia",
  "Turkmenistan",
  "Isole Turks e Caicos",
  "Tuvalu",
  "Uganda",
  "Ucraina",
  "Emirati Arabi Uniti",
  "Regno Unito",
  "Stati Uniti d'America",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Vietnam",
  "Isole Vergini Britanniche",
  "Isole Vergini Americane",
  "Wallis e Futuna",
  "Sahara Occidentale",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

export const NATIONALITIES = [
  "Afghano",
  "Ålandese",
  "Albanese",
  "Algerino",
  "Samoano Americano",
  "Andorrano",
  "Angolano",
  "Anguillano",
  "Antartico",
  "Antiguano",
  "Argentino",
  "Armeno",
  "Arubano",
  "Australiano",
  "Austriaco",
  "Azerbaigiano",
  "Bahamense",
  "Bahreinita",
  "Bengalese",
  "Barbadiano",
  "Bielorusso",
  "Belga",
  "Beliziano",
  "Beninese",
  "Bermudiano",
  "Bhutanese",
  "Boliviano",
  "Bonairiano",
  "Bosniaco",
  "Botswano",
  "Dell'Isola Bouvet",
  "Brasiliano",
  "Del Territorio Britannico dell'Oceano Indiano",
  "Bruneiano",
  "Bulgaro",
  "Burkinabé",
  "Burundese",
  "Capoverdiano",
  "Cambogiano",
  "Camerunese",
  "Canadese",
  "Caymaniano",
  "Centrafricano",
  "Ciadiano",
  "Cileno",
  "Cinese",
  "Dell'Isola di Natale",
  "Delle Isole Cocos",
  "Colombiano",
  "Comoriano",
  "Congolese",
  "Congolese",
  "Delle Isole Cook",
  "Costaricano",
  "Ivoriano",
  "Croato",
  "Cubano",
  "Di Curaçao",
  "Cipriota",
  "Ceco",
  "Danese",
  "Gibutiano",
  "Dominicano",
  "Dominicano",
  "Ecuadoriano",
  "Egiziano",
  "Salvadoregno",
  "Equatoguineano",
  "Eritreo",
  "Estone",
  "Etiope",
  "Delle Isole Falkland",
  "Faroese",
  "Figiano",
  "Finlandese",
  "Francese",
  "Della Guyana Francese",
  "Della Polinesia Francese",
  "Dei Territori Francesi del Sud",
  "Gabonese",
  "Gambiano",
  "Georgiano",
  "Tedesco",
  "Ghanese",
  "Gibilterrino",
  "Greco",
  "Groenlandese",
  "Grenadino",
  "Guadalupense",
  "Guamano",
  "Guatemalteco",
  "Di Guernsey",
  "Guineano",
  "Guineano-Bissau",
  "Guyanese",
  "Haitiano",
  "Delle Isole Heard e McDonald",
  "Vaticano",
  "Honduregno",
  "Di Hong Kong",
  "Ungherese",
  "Islandese",
  "Indiano",
  "Indonesiano",
  "Iraniano",
  "Iracheno",
  "Irlandese",
  "Mannese",
  "Israeliano",
  "Italiano",
  "Giamaicano",
  "Giapponese",
  "Di Jersey",
  "Giordano",
  "Kazako",
  "Keniota",
  "Kiribatiano",
  "Nordcoreano",
  "Sudcoreano",
  "Kuwaitiano",
  "Kirghiso",
  "Laotiano",
  "Lettone",
  "Libanese",
  "Lesothiano",
  "Liberiano",
  "Libico",
  "Liechtensteinese",
  "Lituano",
  "Lussemburghese",
  "Macanese",
  "Macedone",
  "Malgascio",
  "Malawiano",
  "Malaysiano",
  "Maldiviano",
  "Maliano",
  "Maltese",
  "Marshallese",
  "Martinicano",
  "Mauritano",
  "Mauriziano",
  "Di Mayotte",
  "Messicano",
  "Micronesiano",
  "Moldavo",
  "Monegasco",
  "Mongolo",
  "Montenegrino",
  "Montserratiano",
  "Marocchino",
  "Mozambicano",
  "Birmano",
  "Namibiano",
  "Nauruano",
  "Nepalese",
  "Olandese",
  "Neocaledoniano",
  "Neozelandese",
  "Nicaraguense",
  "Nigerino",
  "Nigeriano",
  "Niueano",
  "Dell'Isola Norfolk",
  "Delle Marianne Settentrionali",
  "Norvegese",
  "Omanita",
  "Pakistano",
  "Palauano",
  "Palestinese",
  "Panamense",
  "Papuano",
  "Paraguaiano",
  "Peruviano",
  "Filippino",
  "Di Pitcairn",
  "Polacco",
  "Portoghese",
  "Portoricano",
  "Qatariota",
  "Riunionese",
  "Rumeno",
  "Russo",
  "Ruandese",
  "Di Saint-Barthélemy",
  "Di Sant'Elena",
  "Di Saint Kitts e Nevis",
  "Di Santa Lucia",
  "Di Saint Martin",
  "Di Saint Pierre e Miquelon",
  "Di Saint Vincent e Grenadine",
  "Samoano",
  "Sammarinese",
  "Sãotomense",
  "Saudita",
  "Senegalese",
  "Serbo",
  "Seychellese",
  "Sierra Leonese",
  "Singaporiano",
  "Di Sint Maarten",
  "Slovacco",
  "Sloveno",
  "Salomonese",
  "Somalo",
  "Sudafricano",
  "Della Georgia del Sud e Sandwich Australi",
  "Sudsudanese",
  "Spagnolo",
  "Srilankese",
  "Sudanese",
  "Surinamese",
  "Delle Svalbard",
  "Swazi",
  "Svedese",
  "Svizzero",
  "Siriano",
  "Taiwanese",
  "Tagiko",
  "Tanzaniano",
  "Thailandese",
  "Timorese",
  "Togolese",
  "Tokelauano",
  "Tongano",
  "Trinidadiano",
  "Tunisino",
  "Turco",
  "Turkmeno",
  "Delle Turks e Caicos",
  "Tuvaluano",
  "Ugandese",
  "Ucraino",
  "Emiratino",
  "Britannico",
  "Statunitense",
  "Uruguaiano",
  "Uzbeko",
  "Vanuatuano",
  "Venezuelano",
  "Vietnamita",
  "Delle Isole Vergini Britanniche",
  "Delle Isole Vergini Americane",
  "Di Wallis e Futuna",
  "Sahrawi",
  "Yemenita",
  "Zambiano",
  "Zimbabwese",
];
