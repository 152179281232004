import { useState, useCallback, useEffect } from "react";
import { IOnboardingStep } from "../../../types/onboarding";

// Hook che gestisce i blocchi di campi ripetibili
// Esempio: quando un utente può aggiungere multiple esperienze lavorative
export const useComposableBlocks = (
  steps: IOnboardingStep[],
  stepIndex: number,
  formData: Record<string, any>
) => {
  // Stato che contiene tutti i blocchi di campi ripetibili
  const [composableBlocks, setComposableBlocks] = useState<any[]>([]);

  // Funzione che crea la struttura di un nuovo blocco di campi
  // Viene chiamata quando l'utente vuole aggiungere una nuova esperienza
  const getComposableBlocks = useCallback(
    (si?: number) => {
      let blocks: [NonNullable<IOnboardingStep["fields"]>[number][]] = [[]];

      // Itera su tutti i blocchi definiti nello step
      for (const block of steps[si ?? stepIndex]?.compositeOptions?.blocks ??
        []) {
        // Per ogni blocco, trova i campi corrispondenti
        for (const field of steps[si ?? stepIndex]?.fields ?? []) {
          if (block.fields.includes(field.key)) {
            const foundField = block.fields.find((f) => f === field.key);
            if (foundField) {
              blocks[blocks.length - 1].push(field);
            }
          }
        }
      }
      return blocks;
    },
    [stepIndex, steps]
  );

  // Funzione che restituisce i campi che non fanno parte di blocchi ripetibili
  // Questi campi vengono mostrati una sola volta, fuori dai blocchi
  const getNonComposableFields = useCallback(() => {
    return steps[stepIndex]?.fields?.filter((field) => {
      return !steps[stepIndex]?.compositeOptions?.blocks?.find((block) =>
        block.fields.includes(field.key)
      );
    });
  }, [stepIndex, steps]);

  // Inizializza i blocchi quando cambia lo step
  useEffect(() => {
    if (steps[stepIndex]?.key === "experiences") {
      setComposableBlocks(
        Array.from({ length: formData.experiences.length })
          .fill(getComposableBlocks(stepIndex))
          .flat()
      );
    } else {
      setComposableBlocks(getComposableBlocks() ?? []);
    }
  }, [getComposableBlocks, formData.experiences, stepIndex, steps]);

  return {
    composableBlocks,
    setComposableBlocks,
    getComposableBlocks,
    getNonComposableFields,
  };
};
