import { doc, getFirestore, setDoc } from "firebase/firestore";
import { IOnboardingStep } from "../types/onboarding";
import { app } from "../lib/firebase/config";
import { ONBOARDING_STEPS } from "../constants/onboarding";

export const getNestedValue = (
  obj: any,
  path: string,
  composeIndex?: number,
  stepKey?: string
) => {
  if (typeof composeIndex === "number" && stepKey) {
    const nestedObj = obj[stepKey]?.[composeIndex];
    const newPath = path.replace(`${stepKey}.`, "");
    return newPath.split(".").reduce((acc, part) => acc?.[part], nestedObj);
  }
  return path.split(".").reduce((acc, part) => acc?.[part], obj);
};

export const isStepValid = ({
  mandatoryFields,
  formData,
}: {
  mandatoryFields: string[] | string;
  formData: object;
}): boolean => {
  try {
    let isValid = true;
    Object.keys(formData).forEach((field) => {
      const mFields: string[] = [];
      if (field === "telephoneNumber") {
        isValid =
          (formData[field as keyof typeof formData] as string).length >= 12;
        // const els = (formData[field as keyof typeof formData] as string).split(
        //   " "
        // );
        // if (els.length === 1 || els[1] === "") {
        //   isValid = false;
        // }
      }

      if (typeof mandatoryFields === "string") {
        mFields.push(mandatoryFields);
      } else if (Array.isArray(mandatoryFields)) {
        mFields.push(...mandatoryFields);
      }

      if (!mFields.includes(field)) return;
      if (
        !formData[field as keyof typeof formData] ||
        (mFields &&
          (formData[field as keyof typeof formData] as any[]).length === 0) ||
        formData[field as keyof typeof formData] === ""
      ) {
        isValid = false;
      }
    });

    return isValid;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const combineWorkTypeChoices = ({
  workType,
}: {
  workType: string[];
}) => {};

export const highlightButtonAndProceed = (
  buttonQuerySelector: string,
  highlightClass: string,
  highlightDuration: number,
  onNext: () => void
) => {
  const buttonElement = document.querySelector(
    buttonQuerySelector
  ) as HTMLElement;

  if (!buttonElement) {
    console.warn("No button found with query selector:", buttonQuerySelector);
    return;
  }

  buttonElement.classList.add(highlightClass);

  setTimeout(() => {
    buttonElement.classList.remove(highlightClass);
    onNext();
  }, highlightDuration);
};

export const initializeEmptyStep = (
  step: IOnboardingStep
): Record<string, any> => {
  const emptyStep: Record<string, any> = {};

  // Handle regular fields
  step.fields?.forEach((field) => {
    if (field.key.includes(".")) return;
    if (field.type === "options" && field.multiple) {
      emptyStep[field.key] = [];
    } else if (field.type === "checkbox") {
      emptyStep[field.key] = false;
    } else {
      emptyStep[field.key] = "";
    }
  });

  // Handle composite fields
  if (step.compositeOptions?.blocks) {
    const compositeKey = step.key;
    emptyStep[compositeKey] = [
      // Initialize with one empty block
      step.compositeOptions.blocks.map((block) => {
        const blockData: Record<string, any> = {};
        block.fields.forEach((fieldKey) => {
          const field = step.fields?.find((f) => f.key === fieldKey);
          const _fieldKey = fieldKey.split(".")[1];
          if (field) {
            if (field.type === "options" && field.multiple) {
              blockData[_fieldKey] = [];
            } else if (field.type === "checkbox") {
              blockData[_fieldKey] = false;
            } else {
              blockData[_fieldKey] = "";
            }
          }
        });
        return blockData;
      })[0],
    ];
  }

  return emptyStep;
};

export const initializeEmptyFormData = () => {
  let emptyFormData: Record<string, any> = {};
  ONBOARDING_STEPS.forEach((step) => {
    emptyFormData = { ...emptyFormData, ...initializeEmptyStep(step) };
  });
  return emptyFormData;
};

export const saveToFirebase = async (cf: string, data: Record<string, any>) => {
  const db = getFirestore(app);
  const docRef = doc(db, "external_users", cf);
  await setDoc(
    docRef,
    {
      ...data,
      edit_date: new Date().toISOString(),
    },
    { merge: true }
  );
};

export const adaptNewExperiencesToOldFormat = (
  experiences: Record<string, any>[]
) => {
  if (!experiences?.length) return { experiences: [], count: "0" };

  const adaptedExperiences = experiences.map((exp, index) => {
    const values = {
      esperienza_come: exp.experienceAs || "",
      fine_esperienza: exp.endExperience || "",
      inizio_esperienza: exp.startExperience || "",
      mansioni: exp.mansioni || [],
      modalita_lavoro_esperienza: exp.workModalityExperience || [],
      opzioni_lavoro_esperienza: [],
    };

    return {
      key: `experiences.values[${index}]`,
      label: `Esperienza ${index}`,
      values,
    };
  });

  return {
    experiences: adaptedExperiences,
    count: experiences.length.toString(),
  };
};

export const adaptOldExperiencesToNewFormat = (experiences: {
  experiences: {
    key: string;
    label: string;
    values: Record<string, any>;
  }[];
}) => {
  if (!experiences.experiences?.length) return [];
  const adaptedExperiences = experiences.experiences.map((exp) => {
    return {
      experienceAs: exp.values.esperienza_come,
      endExperience: exp.values.fine_esperienza,
      startExperience: exp.values.inizio_esperienza,
      mansioni: exp.values.mansioni,
      workModalityExperience: exp.values.modalita_lavoro_esperienza,
    };
  });

  return adaptedExperiences;
};
