// File: /src/utils/validators.ts
// Created Date: Friday January 10th 2025
// Author: Christian Nonis <alch.infoemail@gmail.com>
// -----
// Last Modified: Friday January 10th 2025 9:05:36 pm
// Modified By: the developer formerly known as Christian Nonis at <alch.infoemail@gmail.com>
// -----

export const codiceFiscaleValidator = (codiceFiscale: string) => {
  return codiceFiscale?.trim().length === 16;
};

export const telephoneNumberValidator = (telephoneNumber: string) => {
  return telephoneNumber?.trim().length >= 12;
};

export const emailValidator = (email: string) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const maggiorenneValidator = (birthDate: Date) => {
  const today = new Date();
  const _birthDate = new Date(birthDate);
  return today.getFullYear() - _birthDate.getFullYear() >= 18;
};
